import React, { useEffect } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../layout";
import "../../../styles/components/post/_post.scss";
import wrapMarkdownGatsbyImages from "../../../helpers/wrapMarkdownGatsbyImages";
import markdownLoadScripts from "../../../helpers/markdownLoadScripts";
import removeURLInvalidChars from "../../../helpers/removeURLInvalidChars";
export default function BlogPost({ data }) {

  const {title, category, date, description} = data.markdownRemark.frontmatter;
  const {slug} = data.markdownRemark.fields;
  const {html} = data.markdownRemark;

  useEffect(() => {

    wrapMarkdownGatsbyImages();
    markdownLoadScripts();

  }, []) 
  
  return (
    <Layout meta_title={title} meta_desc={description} meta_img={`/assets/metadata_images/generated/blog/${slug.replace(/\//g, "")}.png`}>
          
          <section className='post-frontmatter'>
            <div className="post-frontmatter__inner width-restricted-container">
              <div className='post-frontmatter__breadcrumbs'>
                <Link to='/'>Home</Link> {'>'} <Link to='/blog'>Blog</Link> {'>'} <Link to={`/blog/category/${removeURLInvalidChars(category[0])}`}>{category}</Link>
              </div>
              <h1 className='post-frontmatter__title'>
                {title}
              </h1>
              <div className='post-frontmatter__date'>Posted on <span>{date}</span> by <Link to='/about'>Jonny Mars</Link></div>
              {/* <div className='blog-post-description'>{description}</div> */}
            </div>
          </section>

          <section className='post-content'>
            <div
            className="post-content__inner width-restricted-container"
            dangerouslySetInnerHTML={{ __html: html }}
            />
          </section>
          
    </Layout>
  )
}

export const BlogPostTemplateQuery = graphql`
  query BlogPostTemplateQuery($slug: String) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "MMM D, YYYY")
        description
        tags
        category
      }
      html
    }
  }
`
